<template>
    <div>
        
        <consultation-collecte-modal/>
    </div>
</template>
<script>
import ConsultationCollecteModal from '../components/ConsultationCollecteModal.vue';

export default {

    components:{ConsultationCollecteModal}
}
</script>